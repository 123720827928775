@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.product-target-row,
.convert-factor-objective-container {
  .inline-fields {
    width: 100%;
  }
}

.product-target-row {
  padding-bottom: var(--box-padding-admin);

  .inline-fields {
    flex-direction: column;
  }
}
.convert-factor-objective-container {
  display: flex;
  padding-bottom: var(--box-padding-admin);

  .objectives-list {
    flex-shrink: 0;
  }
}

.associated-pu-list {
  .pu-item {
    .legend-header {
      display: flex;

      .v-btn {
        margin-top: 0;
        margin-left: auto; // aligned to right
      }
    }
  }
}

// Unknowen containers. Could be obsolete (mDube, may 2022)
.product-tolerance-value {
  width: 45%;
}

.form-style {
  background-color: transparent;
  &.wizard {
    max-width: 850px;
  }
}

.product-section-card {
  background-color: var(--color-element-layer1);
}

.expansion-panel-style {
  .v-expansion-panel-header {
    background-color: var(--color-element-layer1);
  }
  .v-expansion-panel-content {
    background-color: var(--color-element-layer1);
  }
}

.conversion-description {
  color: #71797e;
}
.dataSourceName {
  min-width: 300px;
}
.conversionFactor {
  min-width: 100px;
  max-width: 170px;
}
.production-objective-unit {
  width: 160px;
}
